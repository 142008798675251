import logo from './poi.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Watashi ha Tung Desu. Yoroshiku ongeaishimasu!!!
	</p>
        <a
          className="App-link"
          href="https://en.kancollewiki.net/Kancolle_Wiki"
          target="_blank"
          rel="noopener noreferrer"
        >
        </a>
      </header>
    </div>
  );
}

export default App;
